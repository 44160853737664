import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = 'https://greencardusa.mn';

const api = axios.create({
    baseURL: `${BASE_URL}`,
});

axiosRetry(api, {
    retries: 3,
    retryDelay: (retryCount) => retryCount * 1000,
    retryCondition: (error) => error.response?.status >= 500,
});

// Intercept requests to add security headers
api.interceptors.request.use((config) => {
    // Example: Add CSRF Token (if implemented)
    const csrfToken = localStorage.getItem('csrf_token');
    if (csrfToken) {
        config.headers['X-CSRF-TOKEN'] = csrfToken;
    }

    // Example: Add Authorization Header
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export const registerUser = async (userData) => {
    try {
        const data = {
            ...userData,
            action: 'register',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred' };
    }
};

export const loginUser = async (email, password) => {
    try {
        const data = {
            email,
            password,
            action: 'login',
        };

        const response = await api.post('/server.php', data);
        if (response.data?.token) {
            // Save auth token securely
            localStorage.setItem('auth_token', response.data.token);
        }
        return response.data;
    } catch (error) {
        console.error('Login error:', error);
        return { error: 'Login failed.' };
    }
};

export const requestPasswordReset = async (email) => {
    try {
        const data = {
            email,
            action: 'forgot_password',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred. Please try again.' };
    }
};

export const resetPassword = async (token, newPassword) => {
    try {
        const data = {
            token,
            new_password: newPassword,
            action: 'reset_password',
        };

        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred. Please try again.' };
    }
};

export const setForm = async (formData, email) => {
    try {
        const data = {
            ...formData,
            action: 'set_form',
            email,
        };

        const response = await api.post('/server.php', data);
        console.log(response);
        return response.data;
    } catch (error) {
        
        console.log('Form Data:', formData);
        return { error: error.response?.data?.message || 'An error occurred' };
    }
};

export const getUserInfo = async (email) => {
    try {
        const response = await api.get('/server.php', {
            params: {
                email,
                action: 'get_user_info',
            },
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'An error occurred while fetching user information.' };
    }
};

export const getAllUsers = async () => {
    try {
        const response = await api.get('/server.php', {
            params: { action: 'get_all_users' },
        });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error.response?.data || error.message);
        return { error: "Failed to fetch users." };
    }
};

export const getUserDetails = async (userId) => {
    try {
        const response = await api.get('/server.php', {
            params: { action: 'get_user_details', user_id: userId },
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.message || 'Error fetching user details.' };
    }
};

export const logoutUser = () => {
    // Remove auth token on logout
    localStorage.removeItem('auth_token');
    console.log('User logged out.');
};

export const addNews = async (newsData) => {
    try {
        const data = { ...newsData, action: 'add_news' };
        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        console.error('Add News Error:', error.response || error.message);
        return { error: 'Мэдээг нэмэхэд алдаа гарлаа.' };
    }
};

export const getUnverifiedUsers = async () => {
    try {
        const response = await api.get('/server.php', {
            params: { action: 'get_unverified_users' },
        });
        return response.data;
    } catch (error) {
        console.error('Get Unverified Users Error:', error.response || error.message);
        return { error: 'Баталгаажаагүй хэрэглэгчдийг авахад алдаа гарлаа.' };
    }
};

export const verifyUser = async (userId) => {
    try {
        const data = { action: 'verify_user', user_id: userId };
        const response = await api.post('/server.php', data);
        return response.data;
    } catch (error) {
        console.error('Verify User Error:', error.response || error.message);
        return { error: 'Хэрэглэгчийг баталгаажуулахад алдаа гарлаа.' };
    }
};