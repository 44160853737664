import React, { forwardRef } from 'react';

const News = forwardRef(() => {
    const newsItems = [
        {
            title: 'Ногоон картын 2025 оны сугалаа эхэллээ.',
            description: 'Ногоон карт - 2025 Сугалааны урьдчилсан бүртгэл эхэллээ! Юуны өмнө манай сайтад итгэл хүлээлгэсэн нийт хэрэглэгчиддээ',
            imgSrc: 'https://images.pexels.com/photos/356844/pexels-photo-356844.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            imgAlt: 'Ногоон картын мэдээ'
        },
        {
            title: 'Шинэ боломжууд нээлттэй!',
            description: '2025 онд шинээр гарч буй боломжуудыг ашиглаарай!',
            imgSrc: 'https://images.pexels.com/photos/1202723/pexels-photo-1202723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            imgAlt: 'Шинэ боломжууд'
        },
        {
            title: 'Аяллын хөтөлбөр 2025',
            description: '2025 оны зуны аяллын хөтөлбөрүүд танд зориулагдсан байна.',
            imgSrc: 'https://images.pexels.com/photos/208745/pexels-photo-208745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            imgAlt: 'Аяллын хөтөлбөр'
        },
        {
            title: 'Технологийн шинэчлэлт',
            description: 'Технологийн сүүлийн үеийн шинэчлэлтүүд болон инновациуд.',
            imgSrc: 'https://images.pexels.com/photos/3483098/pexels-photo-3483098.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
            imgAlt: 'Технологийн шинэчлэлт'
        },
    ];

    return (
        <div className='flex flex-col items-center w-full'>
            <div className='flex flex-col items-center w-full'>
                <div className='text-pri text-[20px] font-thin'>
                    Онцлох мэдээлэл
                </div>
                <div className='text-white text-[34px] font-bold text-center my-2'>
                    Хамгийн сүүлийн үеийн <br className='hidden sm:block'/>
                    мэдээллийг танд хүргэнэ.
                </div>
            </div>

            <div className='w-full px-6 relative'>
                <div className='flex overflow-x-auto w-full flex-row snap-x snap-mandatory '>
                    {newsItems.map((news, index) => (
                        <div
                            key={index}
                            className='flex-none w-[300px] snap-start min-w-[250px] my-5 mx-2'
                        >
                            <img
                                src={news.imgSrc}
                                alt={news.imgAlt}
                                className='object-cover w-full rounded-xl h-[200px]'
                            />
                            <div className='text-white text-[16px] text-start my-2'>
                                {news.title}
                            </div>
                            <div className='text-gray-400 text-[16px] text-start font-thin'>
                                {news.description}
                            </div>
                            <button className='px-[12px] py-[4px] bg-transparent rounded-md font-thin hover:font-semibold transition-all w-fit border-[1px] border-gray-400 my-3'>
                                <div className='text-white text-[14px]'>
                                    Дэлгэрэнгүй
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default News;
