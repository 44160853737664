import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setForm, getUserInfo } from "../../services/service";
import { UserContext } from "../../userContext";
import { QRCodeCanvas } from "qrcode.react";
import Header from "../layout/Header";

function QPay() {
  const { user, form, setUser } = useContext(UserContext);
  const qrValue = "https://your-link.com";

  const navigate = useNavigate();
  const selectedStatus = form?.["family-status"];

  const handleBack = () => {
    navigate(-1);
  };

  const handleNextPage = async () => {
    try {
      const result = await setForm(form, user.email);
      if (result.error) {
        toast.error(result.error);
      } else {
        const fetchUserInfo = async () => {
          try {
            const response = await getUserInfo(user.email);
            if (response && response.data) {
              setUser(response.data);
            }
          } catch (error) {
            console.error("Failed to fetch user info:", error);
          }
        };
        fetchUserInfo();
        toast.success(
          "Таны мэдээллийг амжилттай бүртгэлээ төлбөр баталгаажих хүртэл түр хүлээнэ үү!"
        );
      }
    } catch (error) {
      toast.error("Серверийн алдаа гарлаа. Дахин оролдоно уу.");
    }
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-evenly min-h-screen w-[95%] sm:w-[70%]">
      <Header
        status={selectedStatus}
        pageNumber={selectedStatus === "married" ? 4 : 3}
      />
      <div className="bg-white p-6 rounded-lg shadow-md">
        <QRCodeCanvas value={qrValue} size={256} className="mx-auto" />
      </div>
      <div className="bg-gray-500 w-full h-[1px]" />
      <div className="flex flex-row w-[90%] md:w-[70%] my-4 items-center justify-end">
        <button
          className="cursor-pointer text-gray-400 hover:text-white mx-5"
          onClick={handleBack}
        >
          Буцах
        </button>
        <button
          className="text-pri bg-button1 px-6 py-3 rounded-lg font-semibold mt-2 hover:bg-gradient-green hover:text-white transition-all duration-300"
          onClick={handleNextPage}
        >
          Төлбөр төлөгдсөн.
        </button>
      </div>
    </div>
  );
}

export default QPay;
