import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCamera } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import { RiArrowDownWideLine, RiArrowUpWideLine } from "react-icons/ri";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import CustomDialog from "../ui/Dialog";
import Header from "../layout/Header";
import male from "../../assets/male.svg";
import { UserContext } from "../../userContext";
import female from "../../assets/female.svg";
import Datepicker from "react-tailwindcss-datepicker";

function FourthForm() {
  const [children, setChildren] = useState([]);
  const [visibleSections, setVisibleSections] = useState([true]);
  const [errors, setErrors] = useState([{}]);
  const [isDialogOpen, setDialogOpen] = useState(true);
  const { form, setForm } = useContext(UserContext);

  const MAX = new Date();
  const navigate = useNavigate();
  const selectedStatus = form?.["family-status"];

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (form?.["child-info"]) {
      setChildren(form?.["child-info"]);
    }
  }, [form]);

  const handleNextPage = () => {
    const validationErrors = children.map(() => ({}));
    children.forEach((child, index) => {
      if (!child.firstName)
        validationErrors[index] = {
          ...validationErrors[index],
          firstName: "Овог заавал бичигдсэн байх ёстой.",
        };
      if (!child.lastName)
        validationErrors[index] = {
          ...validationErrors[index],
          lastName: "Нэр заавал бичигдсэн байх ёстой.",
        };
      if (!child.birthdate)
        validationErrors[index] = {
          ...validationErrors[index],
          birthdate: "Төрсөн огноо заавал бичигдсэн байх ёстой.",
        };
      if (!child.city)
        validationErrors[index] = {
          ...validationErrors[index],
          city: "Хот заавал бичигдсэн байх ёстой.",
        };
      if (!child.gender)
        validationErrors[index] = {
          ...validationErrors[index],
          gender: "Хүйс заавал сонгогдсон байх ёстой.",
        };

      if (!child.uploadedImage) {
        validationErrors[index].file = "Зураг оруулна уу.";
      }
    });

    setErrors(validationErrors);

    setForm((prevForm) => {
      const updatedForm = { ...prevForm, "child-info": children };
      return updatedForm;
    });

    if (
      validationErrors.every(
        (error) => Object.keys(error || {}).length === 0
      ) ||
      children.length === 0
    ) {
      navigate("/qpay");
    }
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedChildren = [...children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      [name]: value,
    };
    setChildren(updatedChildren);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) newErrors[index] = {};
      delete newErrors[index][name];
      return newErrors;
    });
  };

  const handleGenderChange = (index, id) => {
    const updatedChildren = [...children];
    updatedChildren[index].gender =
      id === updatedChildren[index].gender ? null : id;
    setChildren(updatedChildren);

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) newErrors[index] = {};
      delete newErrors[index].gender;
      return newErrors;
    });
  };

  const handleDateChange = (index, date) => {
    if (!date) {
      setErrors((prev) => {
        const newErrors = [...prev];
        if (!newErrors[index]) newErrors[index] = {};
        newErrors[index].birthdate = "Төрсөн огноо оруулна уу.";
        return newErrors;
      });
    } else {
      setChildren((prevChildren) => {
        const newChildren = [...prevChildren];
        newChildren[index].birthdate = date;
        return newChildren;
      });
      setErrors((prev) => {
        const newErrors = [...prev];
        if (!newErrors[index]) newErrors[index] = {};
        delete newErrors[index].birthdate;
        return newErrors;
      });
    }
  };

  const handleFileChange = (index, e) => {
    setDialogOpen(true);
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      const MAX_SIZE = 240 * 1024;

      if (!validImageTypes.includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          file: "Зураг зөвхөн JPG, PNG, GIF форматаар байх ёстой.",
        }));
        return;
      }

      if (file.size > MAX_SIZE) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const MAX_WIDTH = 600;
            const MAX_HEIGHT = 600;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const base64Reader = new FileReader();
                  base64Reader.onloadend = () => {
                    const base64data = base64Reader.result;
                    const resizedImageUrl = URL.createObjectURL(blob);
                    setChildren((prevChildren) => {
                      const newChildren = [...prevChildren];
                      newChildren[index].uploadedImage = resizedImageUrl;
                      newChildren[index].base64 = base64data;
                      return newChildren;
                    });
                  };
                  base64Reader.readAsDataURL(blob);
                }
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(file);
        setErrors((prev) => {
          const newErrors = [...prev];
          if (!newErrors[index]) newErrors[index] = {};
          delete newErrors[index].file;
          return newErrors;
        });
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          const imageUrl = URL.createObjectURL(file);
          setChildren((prevChildren) => {
            const newChildren = [...prevChildren];
            newChildren[index].uploadedImage = imageUrl;
            newChildren[index].base64 = base64data;
            return newChildren;
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const toggleVisibility = (index) => {
    const updatedVisibility = [...visibleSections];
    updatedVisibility[index] = !updatedVisibility[index];
    setVisibleSections(updatedVisibility);
  };

  const addChild = () => {
    setChildren([
      ...children,
      {
        firstName: "",
        lastName: "",
        birthdate: "",
        city: "",
        gender: null,
        uploadedImage: null,
      },
    ]);
    setVisibleSections([...visibleSections, true]);
    setErrors([...errors, {}]);
  };

  const genders = [
    {
      id: "male",
      label: "Эрэгтэй",
      icon: <img src={male} alt="" className="w-[20px] h-[20px]" />,
    },
    {
      id: "female",
      label: "Эмэгтэй",
      icon: <img src={female} alt="" className="w-[20px] h-[20px]" />,
    },
  ];

  return (
    <div className="flex flex-col items-center w-full min-h-screen justify-between">
      <div className="flex flex-col items-center w-[95%] sm:w-[70%]">
        <Header
          status={selectedStatus}
          pageNumber={selectedStatus === "married" ? 3 : 2}
        />
        <div
          className={`w-full p-[20px] md:p-[40px] flex flex-col items-start border-[1px] ${
            children.length > 1 ? "border-gray-600 my-8" : "border-none"
          } rounded-xl`}
        >
          {children.map((child, index) => (
            <div className="flex flex-col w-full" key={index}>
              <div
                className={`text-white text-[18px] md:text-[20px] font-bold text-center my-2 w-full flex justify-between border-b-[1px] border-gray-500 pb-5 px-2 ${
                  index > 0 ? "mt-8" : "mt-0"
                }`}
              >
                <div>Хүүхдийн мэдээлэл {index + 1}</div>
                <button
                  onClick={() => toggleVisibility(index)}
                  className="text-white"
                >
                  {visibleSections[index] ? (
                    <RiArrowUpWideLine />
                  ) : (
                    <RiArrowDownWideLine />
                  )}
                </button>
              </div>
              {visibleSections[index] && (
                <>
                  <div className="flex flex-col sm:flex-row justify-between w-full my-2">
                    <div className="space-y-2 w-full sm:w-[49%] bg-transparent">
                      <Label htmlFor={`firstName-${index}`}>Овог</Label>
                      <Input
                        id={`firstName-${index}`}
                        name="firstName"
                        type="text"
                        placeholder="Овог"
                        value={child.firstName}
                        onChange={(e) => handleInputChange(index, e)}
                        required
                      />
                      {errors[index].firstName && (
                        <p className="text-red-500">
                          {errors[index].firstName}
                        </p>
                      )}
                    </div>
                    <div className="space-y-2 w-full sm:w-[49%]">
                      <Label htmlFor={`lastName-${index}`}>Нэр</Label>
                      <Input
                        id={`lastName-${index}`}
                        name="lastName"
                        type="text"
                        placeholder="Нэр"
                        value={child.lastName}
                        onChange={(e) => handleInputChange(index, e)}
                        required
                      />
                      {errors[index].lastName && (
                        <p className="text-red-500">{errors[index].lastName}</p>
                      )}
                    </div>
                  </div>
                  <Label htmlFor="gender">Хүйс</Label>
                  <div className="flex flex-col sm:flex-row justify-between w-full items-center">
                    {genders.map((e) => (
                      <button
                        key={e.id}
                        onClick={() => handleGenderChange(index, e.id)}
                        className={`flex py-[10px] w-full sm:w-[49%] items-center rounded-md my-4 transition-all ${
                          child.gender === e.id
                            ? "border-2 border-pri"
                            : "border-[1px] border-gray-600"
                        }`}
                      >
                        <div
                          className={`mx-4 p-1 rounded-full border-2 ${
                            child.gender === e.id
                              ? "border-pri"
                              : "border-gray-600"
                          }`}
                        >
                          <div
                            className={`h-[10px] w-[10px] rounded-full ${
                              child.gender === e.id
                                ? "bg-pri"
                                : "bg-transparent"
                            }`}
                          ></div>
                        </div>

                        <div className="flex items-center text-white text-[14px] text-start w-[90%] font-normal">
                          <span className="mr-5">{e.label}</span>
                          {e.icon}
                        </div>
                      </button>
                    ))}
                  </div>
                  {errors[index].gender && (
                    <p className="text-red-500">{errors[index].gender}</p>
                  )}

                  <div className="space-y-2 w-full my-2">
                    <Label htmlFor={`birthdate-${index}`}>Төрсөн огноо</Label>
                    <Datepicker
                      maxDate={MAX}
                      primaryColor={"green"}
                      useRange={false}
                      asSingle={true}
                      value={child.birthdate}
                      onChange={(date) => handleDateChange(index, date)}
                      inputClassName={`border border-gray-500 rounded-md p-2 w-full bg-transparent text-white`}
                    />
                    {errors[index].birthdate && (
                      <p className="text-red-500">{errors[index].birthdate}</p>
                    )}
                  </div>
                  <div className="space-y-2 w-full my-2">
                    <Label htmlFor={`city-${index}`}>Төрсөн хот</Label>
                    <Input
                      id={`city-${index}`}
                      name="city"
                      type="text"
                      placeholder="Хот"
                      required
                      value={child.city}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    {errors[index].city && (
                      <p className="text-red-500">{errors[index].city}</p>
                    )}
                  </div>
                  <Label
                    htmlFor={`upload-photo-${index}`}
                    className="mb-1 mt-2"
                  >
                    Зураг оруулах
                  </Label>
                  <div className="flex flex-col sm:flex-row w-full justify-between my-3">
                    <button
                      className="flex flex-col items-center w-full sm:w-[25%] p-5 border-dashed border-[2px] border-gray-500 rounded-md font-normal text-[14px] focus:outline-none"
                      onClick={() =>
                        document.getElementById(`upload-photo-${index}`).click()
                      }
                    >
                      {child.uploadedImage ? (
                        <div className="w-full flex justify-center">
                          <img
                            src={child.uploadedImage}
                            alt="Uploaded Preview"
                            className="w-[100px] sm:w-[200px] h-[100px] sm:h-[200px] object-cover rounded-md"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="bg-button1 p-3 rounded-full mb-3">
                            <FaCamera className="h-[24px] w-[24px] fill-pri" />
                          </div>
                          <div className="text-gray-400">
                            {" "}
                            Зураг оруулах бол{" "}
                          </div>
                          <div className="text-pri font-normal">
                            {" "}
                            Энд дарна уу.{" "}
                          </div>
                        </>
                      )}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      id={`upload-photo-${index}`}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(index, e)}
                    />
                    <div className="flex flex-col p-5 items-start w-full md:w-[73%] border border-warning rounded-md font-normal text-[14px] text-white mt-3 md:mt-0">
                      <div className="flex items-center mb-1">
                        <GoAlert className="h-[16px] w-[16px] fill-orange-400" />
                        <span className="ml-3 font-semibold">
                          {" "}
                          Зураг сонгохдоо анхаарах зүйлс{" "}
                        </span>
                      </div>
                      <span>
                        {" "}
                        Та одоогийн гэр бүлийн нөхцөл байдлаа сонгоод бүртгэлээ
                        үргэлжлүүлээрэй ингэснээр та сугалаанд хожсон тохиолдолд
                        бичиг баримт бүрдүүлэхэд илүү хялбар байх болно.{" "}
                      </span>
                    </div>
                  </div>
                  {errors[index].file && (
                    <p className="text-red-500">{errors[index].file}</p>
                  )}

                  <CustomDialog
                    isOpen={isDialogOpen}
                    onOpenChange={setDialogOpen}
                    uploadedImage={child.uploadedImage}
                    onClose={() => setDialogOpen(false)}
                  />
                </>
              )}
            </div>
          ))}
        </div>
        <button
          onClick={addChild}
          className="bg-button1 hover:bg-button2 transition-colors text-pri w-full my-4 rounded-md p-3"
        >
          Хүүхэд нэмэх
        </button>
      </div>
      <div className="flex flex-col w-full">
        <div className="bg-gray-500 w-full h-[1px]" />
        <div className="flex flex-row w-[90%] md:w-[70%] my-4 items-center justify-end">
          <button
            className="cursor-pointer text-gray-400 hover:text-white mx-5"
            onClick={handleBack}
          >
            Буцах
          </button>
          <button
            className="text-pri bg-button1 px-6 py-3 rounded-lg font-semibold mt-2 hover:bg-gradient-green hover:text-white transition-all duration-300"
            onClick={handleNextPage}
          >
            Үргэлжлүүлэх
          </button>
        </div>
      </div>
    </div>
  );
}

export default FourthForm;
