import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import appName from "../../assets/app_name.svg";
import { UserContext } from "../../userContext";
import { FaBars, FaTimes, FaSignOutAlt } from "react-icons/fa";
import { toast } from "react-toastify";

function Menu(props) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNewsClick = () => {
    navigate("/");
    setTimeout(props.scrollToNews, 100);
    setIsMenuOpen(false);
  };

  const handleContactClick = () => {
    navigate("/");
    setTimeout(props.scrollToContact, 100);
    setIsMenuOpen(false);
  };

  const handleCont = () => {
    navigate("/login");
    setIsMenuOpen(false);
  };

  const handleLot = () => {
    if (!user.formComplete) {
      navigate("/family-status");
    } else {
      navigate("/dpcode");
    }
    setIsMenuOpen(false);
  };

  const handleHelpClick = () => {
    navigate("/");
    setTimeout(props.scrollToHelp, 100);
    setIsMenuOpen(false);
  };

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    setUser(null); // Clear user context
    localStorage.removeItem("user"); // Clear user data from localStorage
    sessionStorage.removeItem("user"); // Clear user data from sessionStorage
    toast.success("Амжилттай гарлаа!"); // Display toast message
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);

  return (
    <div className="sticky top-0 bg-main flex flex-row items-center justify-between h-fit py-3 w-[94%] mx-auto lg:w-[70%]">
      <button className="flex justify-center" onClick={handleLogoClick}>
        <img src={logo} alt="Website Logo" className="h-[32px] w-auto mx-2" />
        <img src={appName} alt="Website Name" className="h-[32px] w-auto" />
      </button>

      <button
        className="lg:hidden text-white text-2xl bg-gradient-green p-2 rounded-md"
        onClick={toggleMenu}
      >
        {isMenuOpen ? (
          <FaTimes className="text-pri" size={20} />
        ) : (
          <FaBars className="text-pri" size={20} />
        )}
      </button>

      <div
        className={`lg:hidden absolute top-12 left-0 right-0 bg-main flex flex-col items-start space-y-4 py-4 pl-2 transition-transform duration-300 ${
          isMenuOpen ? "show" : "hidden"
        }`}
      >
        {user?.email === "shagai555e@gmail.com" ? (
          <>
            <button
              className="cursor-pointer hover:text-white text-gray-400"
              onClick={() => navigate("/add-news")}
            >
              Мэдээ оруулах
            </button>
            <button
              className="cursor-pointer hover:text-white text-gray-400"
              onClick={() => navigate("/verify-users")}
            >
              Хэрэглэгч баталгаажуулах
            </button>
          </>
        ) : (
          <>
            <button
              className="cursor-pointer hover:text-white text-gray-400"
              onClick={handleNewsClick}
            >
              Мэдээ
            </button>
            <button
              className="cursor-pointer hover:text-white text-gray-400"
              onClick={handleHelpClick}
            >
              Зөвлөгөө
            </button>
            <button
              className="cursor-pointer hover:text-white text-gray-400"
              onClick={handleContactClick}
            >
              Холбоо барих
            </button>
          </>
        )}

        {user ? (
          <button
            className="px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300"
            onClick={handleLot}
          >
            Сугалаанд оролцох
          </button>
        ) : (
          <button
            className="px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300"
            onClick={handleCont}
          >
            Нэвтрэх
          </button>
        )}

        {user && (
          <button
            className="px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300 flex items-center space-x-2"
            onClick={handleLogout}
          >
            <FaSignOutAlt />
            <span>Гарах</span>
          </button>
        )}
      </div>

      <div
        className={`hidden lg:flex w-[30%] justify-evenly text-sm text-gray-400`}
      >
        {user?.email === "shagai555e@gmail.com" ? (
          <>
            <button
              className="cursor-pointer hover:text-white"
              onClick={() => navigate("/add-news")}
            >
              Мэдээ оруулах
            </button>
            <button
              className="cursor-pointer hover:text-white"
              onClick={() => navigate("/verify-users")}
            >
              Хэрэглэгч баталгаажуулах
            </button>
          </>
        ) : (
          <>
            <button
              className="cursor-pointer hover:text-white"
              onClick={handleNewsClick}
            >
              Мэдээ
            </button>
            <button
              className="cursor-pointer hover:text-white"
              onClick={handleHelpClick}
            >
              Зөвлөгөө
            </button>
            <button
              className="cursor-pointer hover:text-white"
              onClick={handleContactClick}
            >
              Холбоо барих
            </button>
          </>
        )}
      </div>

      {user ? (
        user?.email === "shagai555e@gmail.com" ? (
          <button
            className="hidden lg:block px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300 flex items-center space-x-2 ml-4"
            onClick={handleLogout}
          >
            <FaSignOutAlt />
          </button>
        ) : (
          <div className="lg:flex items-center space-x-4">
            <button
              className="hidden lg:block px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300"
              onClick={handleLot}
            >
              {user.formComplete ? "Сугалаанд Дугаар" : "Сугалааны оролцох"}
            </button>
            <button
              className="hidden lg:block px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300 flex items-center space-x-2 ml-4"
              onClick={handleLogout}
            >
              <FaSignOutAlt />
            </button>
          </div>
        )
      ) : (
        <button
          className="hidden lg:block px-4 py-2 rounded-lg text-white bg-gradient-green hover:bg-green-reverse transition-all duration-300"
          onClick={handleCont}
        >
          Нэвтрэх
        </button>
      )}
    </div>
  );
}

export default Menu;
