// ResetPassword.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "../ui/Card";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { resetPassword } from "../../services/service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword() {
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (resetToken === "" || newPassword === "") {
      toast.error("Бүх талбарыг бөглөнө үү.");
      return;
    }

    const response = await resetPassword(resetToken, newPassword);
    if (response.status === "success") {
      navigate("/login");
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <Card className="w-full max-w-sm mx-auto my-1 sm:my-8">
      <ToastContainer />
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Нууц үг шинэчлэх</CardTitle>
        <CardDescription>
          Шинэ нууц үгээ оруулахын өмнө кодоо оруулна уу.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="resetToken">Нууц үг шинэчлэх код</Label>
            <Input
              id="resetToken"
              type="text"
              placeholder="Шинэчлэх код"
              value={resetToken}
              onChange={(e) => setResetToken(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="newPassword">Шинэ нууц үг</Label>
            <Input
              id="newPassword"
              type="password"
              placeholder="Шинэ нууц үг"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <Button
            type="submit"
            className="w-full bg-green-reverse text-white hover:bg-gradient-green"
          >
            Нууц үгээ шинэчлэх
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
