import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Counter from "../shared/Counter";
import torch from "../../assets/torch.png";
import logo from "../../assets/wlogo.svg";
import plane from "../../assets/plane.svg";
import doc from "../../assets/doc.svg";
import payment from "../../assets/payment.svg";
import frame from "../../assets/frame.svg";
import star from "../../assets/star.svg";
import News from "./../shared/News";
import { UserContext } from "../../userContext";
import AdminPanel from "../admin/AdminPanel";

export default function Landing(props) {
  const circles = Array.from({ length: 20 });
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  if (user && user.email === "shagai555e@gmail.com") {
    return <AdminPanel />;
  }

  const handleLot = () => {
    if (user) {
      if (!user.formComplete) {
        navigate("/family-status");
      } else {
        navigate("/dpcode");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="flex flex-col items-center w-full sm:w-[70%] px-4">
      <div className="flex flex-col items-center my-10 sm:my-20">
        <div className="rounded-full h-[60px] w-[60px] sm:h-[70px] sm:w-[71px] bg-white flex items-center justify-center text-[60px] sm:text-[71px] overflow-hidden my-5">
          🗽
        </div>
        <div className="text-gray-400 text-[14px] sm:text-[16px] text-center">
          Америкийн зорих алтан боломж таныг хүлээж байна.
        </div>
        <div className="text-white text-[28px] sm:text-[42px] font-bold text-center my-1">
          Ногоон картын сугалаанд яг одоо оролцож,{" "}
          <br className="hidden sm:block" />
          хожоод, эх дэлхийгээ тойроорой!
        </div>
        <button
          className="text-pri bg-button1 px-4 py-2 sm:px-6 sm:py-3 rounded-lg font-semibold mt-2 hover:bg-gradient-green hover:text-white transition-all duration-300"
          onClick={handleLot}
        >
          Сугалаанд хожих 🎉
        </button>
      </div>
      <div className="flex flex-col items-center w-full">
        <Counter endNumber={30001} duration={3000} />
        <div className="flex justify-center overflow-hidden">
          {circles.map((_, index) => (
            <div
              key={index}
              className="rounded-full h-[20px] w-[20px] sm:h-[32px] sm:w-[32px] bg-white mx-1 my-1"
            ></div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-start w-full my-8 sm:my-12">
        <div className="text-pri text-[16px] sm:text-[20px] font-thin">
          Бүртгүүл тэгээд хож
        </div>
        <div className="text-white text-[28px] sm:text-[34px] font-bold text-start my-2">
          Сугалаанд оролцоход бүх <br className="hidden sm:block" />
          зүйлс хялбар.
        </div>
      </div>
      <div className="flex flex-col sm:flex-row w-full justify-between items-start text-gray-400">
        <div className="flex flex-col items-start w-full sm:w-[45%] mb-4 sm:mb-0">
          <div className="text-[14px] sm:text-[16px] font-thin w-full hover:text-white py-5 border-b border-b-button1 transition-all duration-300 hover:border-b-white">
            Ерөнхий мэдээлэл оруулах болон орчуулга
          </div>
          <div className="flex flex-row hover:text-white py-5 w-full border-b border-b-button1 transition-all duration-300 hover:border-b-white">
            <div className="text-[14px] sm:text-[16px] font-thin mr-2">
              Зураг шалгах болон засварлах, оруулах
            </div>
            <div className="rounded-md text-white text-[10px] sm:text-[12px] font-thin py-1 px-2 bg-gradient-green">
              Шинэ
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[45%] h-[200px] sm:h-[400px] bg-sec rounded-xl"></div>
      </div>
      <div className="flex flex-col w-full h-[300px] sm:h-[516px] rounded-3xl overflow-hidden mt-16 sm:mt-24 relative">
        <div>
          <div className="absolute inset-0 bg-gradient-to-l from-gray-800 to-transparent"></div>
        </div>
        <div className="w-full h-full absolute flex flex-col sm:flex-row justify-evenly p-8 sm:p-14 items-center">
          <div className="w-full sm:w-[45%] h-full sm:h-[80%] flex flex-col justify-between">
            <div className="hidden w-full flex-col sm:block">
              <img
                src={logo}
                alt=""
                className="h-[30px] sm:h-[43px] w-[30px] sm:w-[40px]"
              />
              <div className="text-white text-[28px] sm:text-[34px] text-start my-2">
                Америкийн ногоон картыг хожих хэзээ ч ийм амар байгаагүй.
              </div>
            </div>
            <button
              className="px-[12px] sm:px-[16px] py-[8px] sm:py-[12px] bg-trans rounded-md hover:bg-transHover font-thin hover:font-semibold transition-all w-fit"
              onClick={handleLot}
            >
              <div className="flex flex-row items-center">
                <img
                  src={plane}
                  alt="Plane icon"
                  className="h-[12px] sm:h-[14px] w-[12px] sm:w-[14px] mr-2"
                />
                <div className="text-white text-[12px] sm:text-[14px]">
                  Ердөө 30’000₮
                </div>
              </div>
            </button>
          </div>
          <img
            src={torch}
            alt=""
            className="w-full sm:w-[45%] h-[200px] sm:h-[458px] object-cover"
          />
        </div>
      </div>
      <div className="mb-16 sm:mb-24" ref={props.helpRef}></div>
      <div className="flex flex-col items-start w-full">
        <div className="text-pri text-[16px] sm:text-[20px] font-thin">
          Энгийн ойлгомжтой
        </div>
        <div className="text-white text-[28px] sm:text-[34px] font-bold text-start my-2">
          Сугалаанд оролцоход ердөө <br className="hidden sm:block" />3 алхам
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row justify-between items-start mt-8 sm:mt-12">
        <div className="w-full sm:w-[32%] flex flex-col mb-4 sm:mb-0">
          <div className="p-[16px] sm:p-[20px] bg-button1 rounded-md w-fit">
            <img
              src={doc}
              alt=""
              className="w-[20px] sm:w-[24px] h-[20px] sm:h-[24px]"
            />
          </div>
          <div className="text-white text-[14px] sm:text-[16px] text-start my-2">
            1. Ерөнхий мэдээлэл
          </div>
          <div className="text-gray-400 text-[14px] sm:text-[16px] text-start font-thin">
            Та өөрийн болон гэр бүлийн мэдээллээ оруулахад ердөө 5 минут л
            зарцуулах болно.
          </div>
        </div>

        <div className="w-full sm:w-[32%] flex flex-col mb-4 sm:mb-0">
          <div className="p-[16px] sm:p-[20px] bg-button1 rounded-md w-fit">
            <img
              src={frame}
              alt=""
              className="w-[20px] sm:w-[24px] h-[20px] sm:h-[24px]"
            />
          </div>
          <div className="text-white text-[14px] sm:text-[16px] text-start my-2">
            2. Зураг оруулах
          </div>
          <div className="text-gray-400 text-[14px] sm:text-[16px] text-start font-thin">
            Та өөрийн болон гэр бүлийн зурагнуудаа оруулаад шаардлага хангаж
            байгааг эсэхийг тэр дор нь мэдэх боломжтой.
          </div>
        </div>

        <div className="w-full sm:w-[32%] flex flex-col">
          <div className="p-[16px] sm:p-[20px] bg-button1 rounded-md w-fit">
            <img
              src={payment}
              alt=""
              className="w-[20px] sm:w-[24px] h-[20px] sm:h-[24px]"
            />
          </div>
          <div className="text-white text-[14px] sm:text-[16px] text-start my-2">
            3. Төлбөр төлөх
          </div>
          <div className="text-gray-400 text-[14px] sm:text-[16px] text-start font-thin">
            Та аль болох хялбар аргаар төлбөрөө төлөөд сугалаанд хамрагдахад
            бэлэн болно.
          </div>
        </div>
      </div>
      <div className="mb-8 sm:mb-16" ref={props.newsRef}></div>
      <News />
      <div className="flex flex-col items-center my-20">
        <div className="text-white text-[18px] sm:text-[24px] font-bold text-center my-2">
          "Greencard компанийн тусламжтайгаар би ногоон картын сугалаанд
          амжилттай оролцож, хожсон. Хүсэл мөрөөдлөө биелүүлж, АНУ-д амьдрах
          боломжтой болсондоо маш их баяртай байна. Багийнхан маш найдвартай,
          хурдан ажиллаж, бүх үйл явцыг ойлгомжтой тайлбарлаж өгсөнд талархаж
          байна."
        </div>
        <div className="rounded-full h-[70px] w-[71px] bg-white flex items-center justify-center text-[71px] overflow-hidden my-1"></div>
        <div className="text-white text-[20px] sm:text-[18px]">
          С.Бат-Эрдэнэ
        </div>
        <div className="text-gray-400 text-[14px]">Green card winner</div>
      </div>
      <div className="sticky w-full mb-8">
        <div className="absolute left-1/2 transform -translate-x-1/2 p-[12px] bg-pri rounded-md w-fit">
          <img src={star} alt="Star icon" className="w-[24px] h-[24px]" />
        </div>
        <div className="w-full h-[4px] bg-white-green-diagonal mt-6"></div>
      </div>
      <div className="text-white text-[34px] sm:text-[28px] font-bold text-center my-2">
        Бидний <br />
        ололт амжилт
      </div>
      <div className="w-full flex flex-col sm:flex-row justify-evenly items-start mt-8">
        <div className="w-full sm:w-[32%] flex flex-col items-center mb-4 sm:mb-0">
          <div className="text-pri text-[16px] my-1 font-thin">
            Нийт бүртгэл
          </div>
          <div className="text-gray-400 text-[36px] sm:text-[32px] font-bold">
            30,000+
          </div>
        </div>
        <div className="w-full sm:w-[32%] flex flex-col items-center mb-4 sm:mb-0">
          <div className="text-pri text-[16px] my-1 font-thin">Нийт хожилт</div>
          <div className="text-gray-400 text-[36px] sm:text-[32px] font-bold">
            135
          </div>
        </div>
        <div className="w-full sm:w-[32%] flex flex-col items-center">
          <div className="text-pri text-[16px] my-1 font-thin">
            Нийт хугацаа
          </div>
          <div className="text-gray-400 text-[36px] sm:text-[32px] font-bold">
            3 жил
          </div>
        </div>
      </div>
      <div
        className="w-full h-[450px] rounded-3xl bg-button1 my-16 p-6 sm:p-14"
        ref={props.contactRef}
      >
        <div className="flex flex-col items-center h-full justify-evenly">
          <img src={logo} alt="Logo" className="h-[43px] w-[40px]" />
          <div className="text-white text-[25px] sm:text-[42px] font-bold text-center">
            Хүсэл мөрөөдлөө биелүүлж, шинэ амьдрал руу хамтдаа тэмүүлцгээе!
          </div>
          <div className="text-gray-400 text-[16px] text-center">
            Бүртгэлийн хугацаа хязгаартай тул та яараарай.
          </div>
          <button
            className="text-black bg-white px-6 py-3 rounded-lg font-semibold hover:bg-gradient-green hover:text-white transition-all duration-300"
            onClick={handleLot}
          >
            Сугалаанд хожих 🎉
          </button>
        </div>
      </div>
    </div>
  );
}
