import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "../ui/Card";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../services/service";
import { UserContext } from "../../userContext";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.warning("Бүх талбарыг бөглөнө үү.");
      return;
    }

    try {
      const response = await loginUser(email, password);

      if (response.status === "success") {
        toast.success("Нэвтрэх амжилттай!");
        setUser(response.data);

        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem("user", JSON.stringify(response.data));

        navigate("/");
      } else {
        toast.error(response.message || "Нэвтрэхэд алдаа гарлаа.");
      }
    } catch (error) {
      toast.error("Сервертэй холбогдоход алдаа гарлаа.");
    }
  };

  return (
    <Card className="w-full max-w-sm mx-auto p-3 my-1 sm:my-8">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Нэвтрэх</CardTitle>
        <CardDescription>
          Та сугалаанд оролцохын тулд нэвтрэнэ үү.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">И-мейл</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="password">Нууц үг</Label>
              <Link
                to="/forgot-password"
                className="text-sm text-pri hover:underline"
              >
                Нууц үгээ мартсан?
              </Link>
            </div>
            <div className="relative">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              className="mr-2 bg-pri"
            />
            <Label htmlFor="rememberMe">Намайг сана</Label>
          </div>
          <Button
            type="submit"
            className="w-full bg-green-reverse text-white hover:bg-gradient-green"
          >
            Нэвтрэх
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Бүртгэл байхгүй?{" "}
          <Link to="/register" className="text-pri mx-1 hover:underline">
            Бүртгүүлэх
          </Link>
        </div>
      </CardContent>
      <ToastContainer />
    </Card>
  );
}
