import React, { useState } from "react";
import { toast } from "react-toastify";
import { addNews } from "../../services/service";

const AddNews = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !content) {
      toast.error("Гарчиг болон агуулгыг оруулна уу.");
      return;
    }

    setLoading(true);
    try {
      const response = await addNews({ title, content });
      if (response.status === "success") {
        toast.success("Мэдээ амжилттай нэмэгдлээ.");
        setTitle("");
        setContent("");
      } else {
        toast.error(response.message || "Алдаа гарлаа.");
      }
    } catch (error) {
      toast.error("Серверийн алдаа гарлаа.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Мэдээ нэмэх</h1>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Гарчиг
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            placeholder="Мэдээний гарчиг"
          />
        </div>
        <div>
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-700"
          >
            Агуулга
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            placeholder="Мэдээний агуулга"
            rows={5}
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-gradient-green text-white rounded-lg"
          disabled={loading}
        >
          {loading ? "Илгээж байна..." : "Мэдээ нэмэх"}
        </button>
      </form>
    </div>
  );
};

export default AddNews;
