import React, { useContext } from "react";
import { UserContext } from "../../userContext";

function DPcode() {
  const { user } = useContext(UserContext);
  return (
    <div className="flex flex-col items-center">
      <div className="text-white text-[20px] sm:text-[30px] font-bold text-start my-2">
        {user.DPCode
          ? "Таны сугалааны дугаар"
          : user.verified
          ? "Таны бүртгэл амжилттай баталгаажсан байна. Таны сугалааны дугаарыг удахгүй илгээх болно."
          : "Таны бүртгэл хараахан баталгаажуулаагүй байна."}
      </div>
      <div className="flex flex-row space-x-2">
        {user.DPCode ? (
          user.DPCode.toString()
            .split("")
            .map((char, index) => (
              <div
                key={index}
                className="bg-button1 rounded-lg h-14 w-14 flex items-center justify-center text-white text-2xl font-semibold"
              >
                {char}
              </div>
            ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default DPcode;
