// ForgotPassword.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "../ui/Card";
import { Label } from "../ui/Label";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { requestPasswordReset } from "../../services/service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("И-мэйл хаягаа оруулна уу.");
      return;
    }

    const response = await requestPasswordReset(email);
    if (response.status === "success") {
      navigate("/reset-pass");
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }

    setEmail("");
  };

  return (
    <Card className="w-full max-w-sm mx-auto my-1 sm:my-8">
      <ToastContainer />
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Нууц үг мартсан</CardTitle>
        <CardDescription>Бүртгэлтэй и-мэйл хаягаа оруулна уу.</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">И-мэйл</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <Button
            type="submit"
            className="w-full bg-green-reverse text-white hover:bg-gradient-green"
          >
            Баталгаажуулах код авах
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Нууц үгээ тохируулсан?{" "}
          <Link to="/login" className="text-pri hover:underline mx-1">
            Нэвтрэх
          </Link>
        </div>
      </CardContent>
    </Card>
  );
}
