import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import { getAllUsers, getUserDetails } from "../../services/service";

const AdminPanel = () => {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.email !== "shagai555e@gmail.com") {
      navigate("/");
    } else {
      fetchUsers();
    }
  }, [user, navigate]);

  const fetchUsers = async () => {
    const response = await getAllUsers();
    if (response.status === "success") {
      setUsers(response.data);
    } else {
      console.error("Error fetching users:", response.message);
    }
  };

  const fetchUserDetails = async (userId) => {
    const response = await getUserDetails(userId);
    if (response.status === "success") {
      setUserDetails(response.data);
    } else {
      console.error("Error fetching user details:", response.message);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    fetchUserDetails(user.id);
  };

  const closeDetails = () => {
    setSelectedUser(null);
    setUserDetails(null);
  };

  return (
    <div className="p-6 text-white h-screen w-screen">
      <h1 className="text-3xl font-bold mb-6 text-green-500">Админ самбар</h1>
      <table className="min-w-full bg-gray-800 border border-gray-600 text-left">
        <thead>
          <tr className="bg-green-500 text-black">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Нэр</th>
            <th className="px-4 py-2">И-мэйл</th>
            <th className="px-4 py-2">Гэр бүлийн байдал</th>
            <th className="px-4 py-2">Маягт бөглөсөн</th>
            <th className="px-4 py-2">Төлбөр хийсэн</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr
              key={user.id}
              onClick={() => handleUserClick(user)}
              className="hover:bg-gray-700 cursor-pointer"
            >
              <td className="px-4 py-2 border border-gray-600">{user.id}</td>
              <td className="px-4 py-2 border border-gray-600">{user.name}</td>
              <td className="px-4 py-2 border border-gray-600">{user.email}</td>
              <td className="px-4 py-2 border border-gray-600">
                {user.familyStatus}
              </td>
              <td className="px-4 py-2 border border-gray-600">
                {user.formComplete ? "Тийм" : "Үгүй"}
              </td>
              <td className="px-4 py-2 border border-gray-600">
                {user.paymentComplete ? "Тийм" : "Үгүй"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser && userDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center">
          <div className="bg-gray-900 text-white p-8 rounded-lg max-w-2xl w-full shadow-lg">
            <h2 className="text-2xl font-bold text-green-500 mb-4">
              Хэрэглэгчийн мэдээлэл
            </h2>
            <button
              className="text-red-500 font-bold float-right mb-4"
              onClick={closeDetails}
            >
              Хаах
            </button>

            <h3 className="font-semibold text-green-400">Хувийн мэдээлэл:</h3>
            <p>Овог: {userDetails.personalInfo.first_name}</p>
            <p>Нэр: {userDetails.personalInfo.last_name}</p>
            <p>Хүйс: {userDetails.personalInfo.gender}</p>
            <p>Төрсөн огноо: {userDetails.personalInfo.birthdate}</p>
            <p>И-мэйл: {userDetails.personalInfo.email}</p>
            <p>Хот: {userDetails.personalInfo.city}</p>

            {userDetails.partnerInfo && (
              <>
                <h3 className="font-semibold text-green-400 mt-4">
                  Хамтрагчийн мэдээлэл:
                </h3>
                <p>Овог: {userDetails.partnerInfo.first_name}</p>
                <p>Нэр: {userDetails.partnerInfo.last_name}</p>
                <p>Хүйс: {userDetails.partnerInfo.gender}</p>
                <p>Төрсөн огноо: {userDetails.partnerInfo.birthdate}</p>
                <p>И-мэйл: {userDetails.partnerInfo.email}</p>
                <p>Хот: {userDetails.partnerInfo.city}</p>
              </>
            )}

            {userDetails.childInfo.length > 0 && (
              <>
                <h3 className="font-semibold text-green-400 mt-4">
                  Хүүхдийн мэдээлэл:
                </h3>
                {userDetails.childInfo.map((child, index) => (
                  <div key={index} className="mb-2">
                    <p>Хүүхэд {index + 1}:</p>
                    <p>Овог: {child.first_name}</p>
                    <p>Нэр: {child.last_name}</p>
                    <p>Хүйс: {child.gender}</p>
                    <p>Төрсөн огноо: {child.birthdate}</p>
                    <p>Хот: {child.city}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
