import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUnverifiedUsers, verifyUser } from "../../services/service";

const VerifyUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getUnverifiedUsers();
      if (response.status === "success") {
        setUsers(response.data);
      } else {
        toast.error(
          response.message || "Хэрэглэгчийн мэдээллийг татахад алдаа гарлаа."
        );
      }
    } catch (error) {
      toast.error("Серверийн алдаа гарлаа.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (userId) => {
    try {
      const response = await verifyUser(userId);
      if (response.status === "success") {
        toast.success("Хэрэглэгч амжилттай баталгаажлаа.");
        fetchUsers();
      } else {
        toast.error(response.message || "Алдаа гарлаа.");
      }
    } catch (error) {
      toast.error("Серверийн алдаа гарлаа.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="p-4 h-screen text-white w-screen">
      <h1 className="text-2xl font-bold mb-4 text-green-500">
        Хэрэглэгч баталгаажуулах
      </h1>
      {loading ? (
        <p className="text-green-500">Ачааллаж байна...</p>
      ) : (
        <table className="min-w-full bg-black border border-gray-700">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-700 text-green-500">
                ID
              </th>
              <th className="px-4 py-2 border border-gray-700 text-green-500">
                Нэр
              </th>
              <th className="px-4 py-2 border border-gray-700 text-green-500">
                И-мэйл
              </th>
              <th className="px-4 py-2 border border-gray-700 text-green-500">
                Үйлдэл
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-gray-800">
                <td className="px-4 py-2 border border-gray-700">{user.id}</td>
                <td className="px-4 py-2 border border-gray-700">
                  {user.name}
                </td>
                <td className="px-4 py-2 border border-gray-700">
                  {user.email}
                </td>
                <td className="px-4 py-2 border border-gray-700">
                  <button
                    className="px-3 py-1 bg-green-500 text-black rounded hover:bg-green-600"
                    onClick={() => handleVerify(user.id)}
                  >
                    Баталгаажуулах
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default VerifyUsers;
